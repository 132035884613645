<template>
  <b-container>
    <main class="ecommerce-application">
      <b-card>
        <div class="d-flex justify-content-end">
          <b-button
            variant="primary"
            class="d-flex align-items-center"
            size="sm"
            @click="$bvModal.show('watch-products-modal')"
          >
            <feather-icon size="24" icon="ShoppingBagIcon" />
            <span class="ml-50"> Productos seleccionados</span>
          </b-button>
        </div>
      </b-card>
      <add-store-products-to-supplier-search-bar />
      <b-card>
        <div class="buttons">
          <b-button
            variant="outline-danger"
            size="sm"
            class="d-flex align-items-center"
          >
            <feather-icon size="24" icon="ArrowLeftIcon" />
            <span class="ml-50"> Regresar </span>
          </b-button>

          <b-button
            size="sm"
            class="d-flex align-items-center"
            variant="success"
            @click="handleAddProducts"
          >
            <feather-icon size="24" icon="PlusIcon" />
            <span class="ml-50"> Agregar productos</span>
          </b-button>
        </div>
      </b-card>

      <b-modal
        id="watch-products-modal"
        title="Productos seleccionados"
        size="lg"
        hide-footer
        class="overflow-auto mh-75"
      >
        <b-card v-for="product in supplierProducts" :key="product.id">
          <div
            class="
              d-flex
              align-items-center
              justify-content-between
              product-desc
            "
          >
            <div class="d-flex align-items-center product-desc">
              <b-img
                :src="product.product_attributes.logo"
                :alt="`${product.product_attributes.name}-${product.product_attributes.id}`"
                width="120"
              />
              <div>
                <b-card-title class="mb-0">{{
                  product.product_attributes.name
                }}</b-card-title>
                <b-card-text>
                  {{ product.product_attributes.variant }}
                </b-card-text>
                <b-card-text>
                  <b-badge
                    v-for="category in product.product_attributes
                      .categories_names"
                    :key="category"
                  >
                    {{ category }}
                  </b-badge>
                </b-card-text>
              </div>
            </div>
            <b-button
              variant="danger"
              @click="handleRemoveProduct(product)"
              size="sm"
              class="d-flex align-items-center"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </div>
        </b-card>
      </b-modal>
    </main>
  </b-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex"
import AddStoreProductsToSupplierSearchBar from "@/views/stores/AddStoreProductsToSupplierSearchBar.vue"

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

export default {
  components: {
    AddStoreProductsToSupplierSearchBar,

    ToastificationContent,
  },
  computed: {
    ...mapGetters("users", ["getScopedProducts", "supplierProducts"]),
  },
  methods: {
    ...mapMutations("users", [
      "updateSupplierProducts",
      "updateScopedProducts",
    ]),
    ...mapMutations("pos", ["toggleShowCategories"]),
    ...mapActions("users", ["updateSupplierStoreProducts"]),
    handleAddProducts() {
      this.updateSupplierStoreProducts({
        params: {
          action: "add",
          supplier_id: this.$route.params.supplier_id,
          products_attributes: this.getScopedProducts,
        },
      })
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Productos agregados",
              icon: "CheckIcon",
              variant: "success",
            },
          })
          this.$router.push({
            name: "establishment-proveedores",
            params: { id: this.$route.params.id },
          })
        })
        .catch((error) => {
          console.log(error)
          const msg = error.response.data.messages
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Error",
              text: msg,
              icon: "EditIcon",
              variant: "danger",
            },
          })
        })
    },
    handleRemoveProduct(product) {
      this.updateSupplierProducts(
        this.supplierProducts.filter((p) => p.id !== product.id)
      )
      this.updateScopedProducts(
        this.getScopedProducts.filter((p) => p.id !== product.id)
      )

      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Producto eliminado",
          icon: "TrashIcon",
          variant: "success",
        },
      })
    },
  },
}
</script>

<styles scoped lang="scss">
.buttons {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 600px) {
    button {
      span {
        display: none;
      }
    }
  }
}

.product-desc {
  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
}
</styles>
