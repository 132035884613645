<template>
  <b-container>
    <!-- Products search -->
    <b-card class="mb-2">
      <h3>Asigna productos al proveedor</h3>
      <p>Buscar productos por nombre o código de barras</p>
      <b-input-group>
        <b-form-input
          id="sku"
          v-model="searchQuery"
          variant="outline-primary"
          placeholder="Nombre o Código de barras"
          @input="lookupStoreProducts"
        />
        <b-input-group-append v-if="!searchQuery">
          <b-button
            class="mr-1"
            variant="primary"
            @click="$bvModal.show('new-product-scanner')"
          >
            <i class="fas fa-qrcode" />
          </b-button>
          <b-modal id="new-product-scanner">
            <StreamBarcodeReader @decode="onDecode" />
          </b-modal>
          <!--
            <b-dropdown
            no-caret
            right
            variant="outline-primary"
          >
            <template #button-content>
              🔧<span class="sr-only">settings</span>
            </template>
            <b-dropdown-item @click="toggleCameraScanner()">
              {{ isCameraScannerActive ? '🎥 Ocultar Camara' : '🎥 Mostrar camara' }}
            </b-dropdown-item>
          </b-dropdown>
          -->
        </b-input-group-append>
        <b-input-group-append v-else>
          <b-button
            variant="outline-warning"
            @click="clearSearchbarAndResetSearch()"
          >
            Borrar
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-card>
    <div v-if="searchQuery" class="checkout-items list-view">
      <!-- SEARCH RESULTS -->
      <b-card
        v-for="product in productsShown"
        :key="product.id"
        class="ecommerce-card mb-1 shadow border"
        no-body
      >
        <!-- Product Image -->
        <div>
          <b-link>
            <b-img
              :src="product.product_attributes.logo"
              :alt="`${product.product_attributes.name}-${product.product_attributes.id}`"
              width="120"
            />
          </b-link>
        </div>

        <!-- Product Details: Card Body -->
        <b-card-body>
          <div class="item-name">
            <h6 class="mb-0">
              <b-link class="text-body">
                {{ product.product_attributes.name }}
              </b-link>
            </h6>
            <div class="item-rating">
              {{ product.product_attributes.variant }}
            </div>
            <b-card-text>{{ product.store_attributes.name }}</b-card-text>
            <div>
              <b-badge
                v-for="cat in product.product_attributes.categories_names"
                :key="`cat-${cat}`"
              >
                {{ cat }}
              </b-badge>
            </div>
          </div>
        </b-card-body>

        <!-- Product Options/Actions -->
        <div class="item-options text-center">
          <div class="item-wrapper">
            <div class="item-cost">
              <p v-if="product.hasFreeShipping" class="card-text shipping">
                <b-badge pill variant="light-success"> Free Shipping </b-badge>
              </p>
            </div>
          </div>
          <b-button
            :variant="
              scopedProducts.some(
                (p) =>
                  p.product_id === product.product_attributes.id &&
                  p.store_id === product.store_attributes.id
              )
                ? 'secondary'
                : 'success'
            "
            class="mt-1 remove-wishlist"
            @click="handleAddProduct(product)"
            :disabled="
              scopedProducts.some(
                (p) =>
                  p.product_id === product.product_attributes.id &&
                  p.store_id === product.store_attributes.id
              )
            "
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span
              v-if="
                scopedProducts.some(
                  (p) =>
                    p.product_id === product.product_attributes.id &&
                    p.store_id === product.store_attributes.id
                )
              "
            >
              Agregado
            </span>
            <span v-else>Agregar</span>
          </b-button>
        </div>
      </b-card>

      <!-- PAGINATION -->
      <b-pagination
        v-if="pagination.total_objects > pagination.per_page"
        v-model="pagination.page"
        :total-rows="pagination.total_objects"
        :per-page="pagination.per_page"
        first-number
        last-number
        class="mt-1 mt-sm-0 mx-auto mb-1"
        prev-class="prev-item"
        next-class="next-item"
        @change="
          (value) => {
            handlePagination({ page: value, per_page: pagination.per_page })
          }
        "
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </div>
  </b-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex"
import { debounce } from "lodash"
import { StreamBarcodeReader } from "vue-barcode-reader"

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

export default {
  components: {
    StreamBarcodeReader,
    ToastificationContent,
  },
  props: {
    barcodeScanned: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      searchQuery: "",
      isCameraScannerActive: false,
      isCategoriesActive: false,
      scopedProducts: [],
      supplierProducts: [],
    }
  },
  computed: {
    ...mapGetters("stores", ["productsShown", "pagination"]),
    ...mapGetters("pos", ["settings"]),
  },
  watch: {
    barcodeScanned(val) {
      this.searchQuery = val
      this.lookupStoreProducts(val)
    },
  },
  methods: {
    onDecode(result) {
      this.searchQuery = result
      this.$bvModal.hide("new-product-scanner")
    },
    ...mapMutations("pos", ["toggleShowCategories"]),
    ...mapMutations("users", [
      "updateScopedProducts",
      "updateSupplierProducts",
    ]),
    ...mapActions("stores", ["fetchStoreProducts"]),
    ...mapActions("pos", ["addProductToCart"]),
    lookupStoreProducts: debounce(function searchQuery(query) {
      if (/^\d*$/.test(query) && query != null && query !== "") {
        const barcodeWithOutLastDigit = query.substring(0, query.length - 1)
        this.fetchStoreProducts({
          by_sku: Number(barcodeWithOutLastDigit),
        }).then((response) => {
          if (response.data.length === 1) {
            if (navigator.vibrate) {
              navigator.vibrate(200)
            }
          }
        })
      } else if (query != null && query !== "") {
        this.fetchStoreProducts({
          by_name: query,
        })
      } else if (query === null || query === "") {
        this.fetchStoreProducts({})
      }
    }, 250),
    handlePagination({ page, per_page }) {
      this.fetchStoreProducts({
        by_name: this.searchQuery,
        meta: {
          pagination: {
            page,
            per_page,
          },
        },
      })
    },
    clearSearchbarAndResetSearch() {
      this.searchQuery = ""
    },
    pushScopedProducts(product, store) {
      this.scopedProducts.push({
        product_id: product,
        store_id: store,
      })
      this.updateScopedProducts(this.scopedProducts)

      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Producto agregado",
          icon: "CheckIcon",
          variant: "success",
        },
      })
    },
    pushSupplierProducts(product) {
      this.supplierProducts.push(product)
      this.updateSupplierProducts(this.supplierProducts)
    },
    handleAddProduct(product) {
      this.pushScopedProducts(
        product.product_attributes.id,
        product.store_attributes.id
      )
      this.pushSupplierProducts(product)
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
